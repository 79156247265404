.App {
  text-align: center;
}
body {
  padding: 0%;
  margin: 0%;
  font-family: Arial, Helvetica, sans-serif;
}

nav {
  margin: 0;
  width: 100%;
  height: 80px;
  background-color:black;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  text-decoration: none;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 4rem;
}

.about{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-bottom: 2px solid;
  border-radius: 2rem;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: larger;
  font-weight: bolder;
  height: 3rem;
  width: 8rem;
  align-items: center;
}


.about-cont{
  width: 50%;
  max-width: 60%;
  height: 50%;
  font-size: 25px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  width: fit-content;
  block-size: fit-content;
  text-align: justify;
  max-height: 600px;
  background-color:whitesmoke;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: auto;
  margin-top: 100px;
  padding: 20px;
  border-radius: 15px;
}

.about-cont h2{
  text-align: center;
}

.logo{
  display: block;
  flex-direction: row;
  align-items: center;
  position: relative;
}

img{
  height: 70px;
  width: 130px;
}

#text-logo{
  position: absolute;
  left: 75%;
  z-index: 1;
}


.login button{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border: 2px solid white;
  border-radius: 2rem;
  font-family:Comic Sans MS;
  background-color: black;
  font-size: larger;
  font-weight: bolder;
  height: 3rem;
  width: 8rem;
  align-items: center;
}

.signout button{
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-weight: bold;
  font-size: large;
  align-items: center;
  height: 3rem;
  width: 8rem;
  border-radius: 1rem;
}

.signout span {
  height: 1rem;
  width: 2rem;
  padding: 0.2px;
}

.posting button{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  font-weight: bold;
  font-family:Comic Sans MS;
  align-items: center;
  background-color: black;
  border: whitesmoke solid 2px;
  height: 2.8rem;
  width: 14rem;
  border-radius:2rem;
}

.posting span {
  height: 1rem;
  width: 2rem;
  padding: 0.2px;
  align-items: center;
}

a {
  color: white;
  text-decoration: none;
  margin: 10px;
  font-size: 25px;
}

.createPostPage {
  width: 100%;
  height: calc(100vh - 80px);
  display: grid;
  place-items: center;
}

.cpContainer {
  width: 500px;
  height: auto;
  padding: 20px;
  background-color:burlywood;
  border-radius: 12px;
  color: black;
  display: flex;
  flex-direction: column;
}

.cpContainer h1 {
  text-align: center;
}

.cpContainer label {
  font-size: 25px;
}
.cpContainer .inputGp {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.inputGp input,
.inputGp textarea {
  font-size: 18px;
  border: none;
  border-radius: 2px;
  padding: 5px;
}

.inputGp input {
  height: 40px;
}
.inputGp textarea {
  height: 150px;
}

.cpContainer button {
  margin-top: 20px;
  height: 40px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
}
input,
textarea {
  margin-top: 5px;
}

.loginPage {
  width: 100vw;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginPage p {
  font-size: 30px;
}

/* GOOGLE BUTTOn */
.login-with-google-btn {
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 5px 16px 12px 42px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 5px 0 rgba(0, 0, 0, 0.04), 0 2px 2px rgba(0, 0, 0, 0.25);
  color: black;
  font-size: 20px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;
}
.login-with-google-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}
.login-with-google-btn:active {
  background-color: #eeeeee;
}
.login-with-google-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}
.login-with-google-btn:disabled {
  filter: grayscale(100%);
  background-color: red;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}

.homePage {
  width: 100%;
  min-height: calc(100vh - 80px);
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.homePage .post {
  width: 700px;
  height: auto;
  max-height: 600px;
  background-color:oldlace;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin: 20px;
  padding: 20px;
  border-radius: 15px;
}

.post .postHeader {
  display: flex;
  justify-content: center;
  width: 100%;
}

.postHeader .title {
  flex: 100%;
  border-radius: 2rem;
}
.postHeader .deletePost {
  flex: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.deletePost button {
  border: none;
  background: none;
  font-size: 30px;
  cursor: pointer;
}

h5{
  font-style: italic;
}

.post .postTextContainer {
  text-align: justify;
  word-wrap: break-word;
  height: auto;
  max-height: 300px;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding: 5px;
}

.pic{
  height: 50px;
}